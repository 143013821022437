import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue'

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/',
  //   redirect: '/dashboard/home'
  // },

  {
    path: '/',
    name: "sign_in_ui",
 
    component: () => import('@/views/SignInPage.vue')
  },

  {
    path: '/dashboard-page',
    name: "onboard_ui",
 
    component: () => import('@/views/Onboard.vue')
  },
  
  {
    path: '/view-product',
    name: "view_product_ui",
 
    component: () => import('@/views/ViewProductPage.vue')
  },
  {
    path: '/view-checkout',
    name: "view_checkout_ui",
 
    component: () => import('@/views/ViewCheckoutPage.vue')
  },
  {
    path: '/make-payment',
    name: "make_payment_ui",
 
    component: () => import('@/views/MakePaymentPage.vue')
  },
  {
    path: '/thank-you-order',
    name: "thank_you_order",
 
    component: () => import('@/views/ThankYouOrder.vue')
  },
  
  {
    path: '/register',
    name: "register_ui_page",
 
    component: () => import('@/views/RegisterPage.vue')
  },
  {
    path: '/forgot-password',
    name: "forgot_password_page",
 
    component: () => import('@/views/ForgotPage.vue')
  },
  {
    path: '/customer-orders',
    name: "customer_orders_page",
 
    component: () => import('@/views/CustomerOrdersPage.vue')
  },
  {
    path: '/reviews-page',
    name: "review_ui_page",
 
    component: () => import('@/views/ReviewsPage.vue')
  },
  {
    path: '/saved-items',
    name: "saved_items_page",
 
    component: () => import('@/views/SavedItemsPage.vue')
  },
  {
    path: '/customer-shipping',
    name: "customer_shipping_ui_page",
 
    component: () => import('@/views/ShippingPage.vue')
  },
  {
    path: '/admin-orders',
    name: "admin_orders_ui",
 
    component: () => import('@/views/AdminOrdersPage.vue')
  },
  {
    path: '/admin-customer',
    name: "admin_customer_ui",
 
    component: () => import('@/views/AdminCustomerPage.vue')
  },
  {
    path: '/admin-settings',
    name: "admin_settings_ui",
 
    component: () => import('@/views/AdminSettingsPage.vue')
  },
  {
    path: '/manage-products',
    name: "admin_manage_products_ui",
 
    component: () => import('@/views/AdminManageProductPage.vue')
  },
  {
    path: '/admin-product-variations',
    name: "admin_product_variation_ui",
 
    component: () => import('@/views/AdminProductVariationPage.vue')
  },


  {
    path: '/get-access',
    name: "get_access_ui",
 
    component: () => import('@/views/GetAccess.vue')
  },
  {
    path: '/dashboard/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/dashboard/home'
      },
      {
        path: 'home',
        component: () => import('@/views/HomePage.vue')
      },
      {
        path: 'tutorial',
        component: () => import('@/views/TutorialPage.vue')
      },
      {
        path: 'booking',
        component: () => import('@/views/BookingPage.vue')
      },
      {
        path: 'message',
        component: () => import('@/views/MessagePage.vue')
      },
      {
        path: 'winnings',
        component: () => import('@/views/WinningsPage.vue')
      },
      {
        path: 'help',
        component: () => import('@/views/HelpPage.vue')
      },
      {
        path: 'start',
        component: () => import('@/views/StartPage.vue')
      },
      {
        path: 'disclaimer',
        component: () => import('@/views/DisclaimerPage.vue')
      },
      {
        path: 'subscribe',
        component: () => import('@/views/SubscribePage.vue')
      },
      {
        path: 'configure',
        component: () => import('@/views/ConfigurePage.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
